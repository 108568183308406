import React, { 
    FC, 
    useEffect, 
    useState,
} from "react";

import './SearchBox.css';
import { colors } from "../../styles/colors";
import { SubheaderContainer } from "../commons/Subheader";
import { StyledButton } from "../commons/Button";
import { useParams } from "react-router-dom";
import Autosuggest from 'react-autosuggest';

interface ISearchBox {
    onSearch: (nick: string) => void;
    setHeroNick: (nick: string) => void;
    isLoading: boolean;
    heroNick?: string;
};

export const searchBoxAsianRegex = new RegExp('^.*([^a-zA-Z0-9])+.*$');

export const SearchBox: FC<ISearchBox> = ({
    onSearch,
    isLoading,
    heroNick,
}) => {

    const params = useParams();

    const [value, setValue] = useState(heroNick || '');
    const [suggestions, setSuggestions] = useState([]);
    const [data, setData] = useState([]);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const groupId = user?.groupId;

    useEffect(() => {
        if (!!params?.nick) {
            onSearch(value);
        };

        fetch(`http://146.59.44.6:8080/player${!!groupId ? '?groupId=' + groupId : ''}`, { 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => {
            setData(json);
        });
    }, []);
  
    const onSuggestionsFetchRequested = ({value}: { value: string}) => {
      const inputValue = value.trim().toLowerCase();
      const filteredSuggestions = data.filter((user: { playerNick: string }) => user.playerNick.toLowerCase().startsWith(inputValue));
      setSuggestions(filteredSuggestions);
    };
  
    const onSuggestionsClearRequested = () => {
      setSuggestions([]);
    };
  
    const getSuggestionValue = (suggestion: { playerNick: string}) => suggestion.playerNick;
  
    const renderSuggestion = (suggestion: { playerNick: string}) => (
      <div>{suggestion.playerNick}</div>
    );

    return (<SubheaderContainer>
        <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
            onSuggestionsClearRequested={onSuggestionsClearRequested}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{
                placeholder: 'Player nick',
                value,
                onChange: (event: any, { newValue }: { newValue: string }) => setValue(newValue),
                style: { zIndex: '100000', width: '400px', fontSize: '20px', boxSizing: 'border-box', padding: '8px 20px', borderRadius: '16px 16px 0 0' },
            }}
            theme={{
                suggestionsContainer: 'custom-suggestions-container',
                suggestion: 'custom-suggestion',
                suggestionHighlighted: 'custom-suggestion-highlighted',
            }} />
        <StyledButton 
            isActive={!isLoading}
            disabled={isLoading}
            onClick={() => onSearch(value)}
            backgroundColor={colors.buttons.download}>
            SEARCH
        </StyledButton>
    </SubheaderContainer>);
};