import React, { 
    FC, 
    useEffect, 
    useState, 
} from "react";

import IPlayer from "../../types/Player.types";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import ICard from "../../types/Card.types";
import { Card } from "../commons/Card";
import ITag from "../../types/Tag.types";
import { Tag } from "../commons/Tag";
import { INote } from "../../types/Note.types";
import icons, { Icon } from "../commons/Icon";

interface IPlayerInfo {
    player: IPlayer;
    positions: string[];
    bb: number;
    cards: ICard[];
    heroNick: string;
    playerUuid: string;
    allTags?: ITag[];
    allNotes?: INote[];
    idx?: number;
}

export const PlayerInfo: FC<IPlayerInfo> = ({ 
    player, 
    positions,
    bb,
    cards,
    heroNick,
    playerUuid,
    allTags,
    allNotes,
    idx,
}) => {

    const isHero = player.nick === heroNick;
    const [counter, setCounter] = useState<number>(0);
    const [winRate, setWinRate] = useState<number>(0);
    const [pfr, setPfr] = useState<number>(0);
    const [vpip, setVpip] = useState<number>(0);
    const [tags, setTags] = useState<ITag[]>([]);
    const [playerNotes, setPlayerNotes] = useState<INote[]>([]);
    const [isError, setIsError] = useState<boolean>(false);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const groupId = user?.groupId;

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/player/${player.nick}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : null)
        .then(json => {
            if (json) {
                setCounter(json.handsPlayed);
                if (json.handsPlayed > 0) {
                    setWinRate(Math.round(10000 * json.balanceInBB / json.handsPlayed) / 100);
                    setVpip(Math.round(10000 * json.vpip / json.handsPlayed) / 100);
                    setPfr(Math.round(10000 * json.pfr / json.handsPlayed) / 100);
                }
            }
        });

        const filteredTags = allTags?.filter((tag: ITag) => (tag?.players?.filter((innerPlayer: any) => innerPlayer?.uuid === playerUuid)?.length || 0) > 0);
        setTags(filteredTags || []);
    }, []);

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/notePlayer?nick=${player.nick}${!!groupId ? '&groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => {
            if (res.ok) {
                return res.json()
            } else {
                return null
            }
        }).then(json => !!json && setPlayerNotes(json))
        .catch(e => setIsError(true));
    }, []);

    return (<Row underline={true} onClick={() => {
        window.location.href = `/players/${player.nick}/hands`;
    }}>
        <Nick isHero={isHero}>{ positions[idx || 0] }</Nick>
        <Nick isHero={isHero}>
            <div>{ player.nick } {isHero && '(Hero) ' }({counter})</div>
            <Stat balanceInBB={winRate}><span>{winRate}</span> bb/100</Stat> 
            <div>VPIP: <span>{ vpip.toFixed(2) } %</span></div> 
            <div>PFR: <span>{ pfr.toFixed(2) } %</span></div> 
        </Nick>
        <Row gap={4} fitContent={true}>
        {
            cards.map((card: ICard) => (<Card 
                background={colors.cards[card.color]} 
                figure={card.figure} />))
        }
        </Row>
        <div>{ player.stack }</div>
        <div>{ Math.round(player.stack / bb) } bb</div>
        { !!playerNotes && playerNotes.length > 0 ? (<Icon 
            icon={icons.CopyIcon} 
            height={18} 
            fill={colors.whiteStrong} 
            tooltip={playerNotes.length > 0 && playerNotes.map(note => <div>- {note.note}</div>)}
            onClick={() => {}} />) : <div></div>
        }
        <TagsDiv>
        {
            tags?.filter(tag => !!tag).map((tag: ITag) => (<Tag uuid={tag.uuid} name={tag.name} color={tag.color} bgColor={tag.bgColor} />))
        }
        </TagsDiv>
    </Row>);
};

const Row = styled.div<{gap?: number, fitContent?: boolean, underline?: boolean}>`
    display: flex;
    width: 100%;
    gap: ${p => p.gap}px;
    align-items: center;
    cursor: ${p => p.underline && 'pointer'};

    &:hover {
        opacity: 0.7;
    }


    > div {
        width:${p => p.fitContent ? 'fit-content' : '150px'};
    }

    > div:first-child {
        width: 60px;
    }

    > div:nth-child(3) {
        width: 100px;
    }

    > div:nth-child(4) {
        width: 100px;
        color: ${colors.whiteStrong};
    }

    > div:nth-child(5) {
        width: 100px;
    }

    > div:nth-child(6) {
        width: 30px;
    }

    > div:last-child {
        width: fit-content;
    }
`;

const Nick = styled.div<{isHero: boolean, }>`
    color: ${p => p.isHero && colors.highlight};

    > div {
        margin-bottom: 2px;
    }

    span {
        font-size: 16px;
        font-weight: 700;
    }
`;

const Stat = styled.div<{balanceInBB?: number}>`
    color: ${p => (p.balanceInBB || 0) > 0 ? colors.success : colors.failureStrong};
`;

const TagsDiv = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    width: 280px !important;
`;