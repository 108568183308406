import React, { 
    FC,
    useEffect,
    useState,
} from "react";

import { Header } from "../Header/Header";
import { styled } from "styled-components";
import { colors } from "../../styles/colors";
import { Table } from "../Table/Table";
import icons, { Icon } from "../commons/Icon";
import IPlayerGlobal from "../../types/IPlayerGlobal";
import { PlayerHands } from "../PlayerHands/PlayerHands";
import IHand from "../../types/Hand.types";
import { Filters } from "../PlayerHands/Filters/Filters";

export interface ITable {
    nicks: string[];
    id: number;
    idx?: number;
    removeItem?: (id: number) =>  void;
    renderRemoveIcon?: boolean;
    players?: IPlayerGlobal[];
    setTableNicks?: (nicks: string[]) => void;
}

export const Tables: FC = () => {

    const [tables, setTables] = useState<ITable[]>([{
        nicks: ['', '', '', '', '', '', '', '', ''],
        id: new Date().getTime()
    }]);
    const [selectedStakes, setSelectedStakes] = useState<number[][]>([]);
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [players, setPlayers] = useState<IPlayerGlobal[]>([]);
    const [ready, setReady] = useState<boolean>(false);
    const [blocked, setBlocked] = useState<boolean>(false);
    const [playerHands, setPlayerHands] = useState<IHand[]>([]);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/hand/newest?limit=10${!!userId ? '&uuid=' + userId : ''}${!!groupId ? '&groupId=' + groupId : ''}${selectedStakes.length > 0 ? '&stakes=' + selectedStakes.map(stake => stake[0]) : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.text())
        .then(text => JSON.parse(text))
        .then(json => {
            setPlayerHands(json);
        });

        fetch(`http://146.59.44.6:8080/player${!!groupId ? '?groupId=' + groupId : ''}`, { 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => {
            setPlayers(json);
            setReady(true);
        });

        const query = new URLSearchParams(window.location.search).get('data');
        if (!!query) {
            let finalTables = JSON.parse(query);
            const internalTables: ITable[] = [];
            finalTables.forEach((table: string[]) => {
                internalTables.push({
                    nicks: table,
                    id: new Date().getTime()
                });
            });
            setTables(internalTables);
        }
    }, []);

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/hand/newest?limit=10${!!userId ? '&uuid=' + userId : ''}${!!groupId ? '&groupId=' + groupId : ''}${selectedStakes.length > 0 ? '&stakes=' + selectedStakes.map(stake => stake[0]) : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.text())
        .then(text => JSON.parse(text))
        .then(json => {
            setPlayerHands(json);
        });
    }, [searchTrigger]);

    useEffect(() => {
        if (blocked) {
            window.history.replaceState( {} , '',  `/tables?data=${JSON.stringify(tables.map(table => table.nicks))}`);
        }
        setBlocked(false);
    }, [tables]);

    return (<>
        <Header itemSelected={7} />
        <TablesContainer>
            <h1>TABLES VIEW</h1>
            <TablesBox>
                { tables.map((table: ITable, idx: number) => <Table
                    nicks={table.nicks} 
                    id={table.id} 
                    idx={idx}
                    players={players}
                    setTableNicks={(newNicks: string[]) => {
                        setTables((prevTables: ITable[]) => {
                            prevTables[idx].nicks = newNicks;
                            return [...prevTables];
                        })
                    }}
                    renderRemoveIcon={tables.length > 1}
                    removeItem={(id: number) => {
                        setTables((prevState: ITable[]) => {
                            return prevState.filter(table => table.id !== id);
                        })
                    }} />) }
                <Icon 
                    icon={icons.AddIcon} 
                    height={50}
                    onClick={() => {
                        setBlocked(true);
                        setTables((prevState: ITable[]) => {
                            const newState = [...prevState];
                            newState.push({
                                id: new Date().getTime(),
                                nicks: ['', '', '', '', '', '', '', '', '']
                            })
                            return newState;
                        });
                    }} 
                    fill={colors.whiteStrong}
                    stroke={colors.whiteStrong} />
            </TablesBox>
        </TablesContainer>
        <Filters selectedStakes={selectedStakes} setSelectedStakes={setSelectedStakes} setSearchTrigger={setSearchTrigger} withClearButton={true} />
        <PlayerHands
            isLoading={!ready}
            available={!blocked}
            hideTotalBalance={true}
            hands={playerHands}
            selectedHandUuid={''}
            heroNick={''}
            onGlobalClick={(hand: IHand | null) => {
                setBlocked(true);
                if (!!hand && !!hand.players) {
                    const finalNicks = hand.players.map(player => player.nick).concat(Array(9 - hand.players.length));
                    for (let i = 1; i < 5; i++) {
                        const tmp = finalNicks?.[i] || '';
                        finalNicks[i] = finalNicks?.[finalNicks?.length - i] || '';
                        finalNicks[finalNicks?.length - i] = tmp;
                    }
                    setTables((prevState: ITable[]) =>
                    {
                        const newState = [...prevState];
                        newState.push({
                            id: new Date().getTime(),
                            nicks: finalNicks
                        });
                        return newState;
                    });
                }
            }} />
    </>);
};

const TablesContainer = styled.div`
    max-width: 100vw;
    overflow: scroll;
    margin-top: 127px;
    color: ${colors.whiteStrong};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div > div:last-child {
        transform: translateY(-20px);
    }
`;

const TablesBox = styled.div`
    margin: 12px auto;
    margin-top: 96px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px 200px;
    align-items: center;
    justify-content: center;

    > div:last-child {
        justify-self: center;
    }
`;