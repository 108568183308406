import React, { FC, useEffect, useState } from "react";
import { MultiSelect } from "react-multi-select-component";
import { Wrapper } from "../PlayerHands/Filters/Filters.styles";

interface IProps {
    setType: React.Dispatch<React.SetStateAction<string>>;
    setSubType: React.Dispatch<React.SetStateAction<string>>;
    setSearchTrigger: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ISelect {
    label: string;
    value: string;
}

export const TypeFilters: FC<IProps> = ({ setSearchTrigger, setType, setSubType }) => {

    const visualTypes = [{
        label: 'Newest',
        value: 'newest'
    }, {
        label: 'Most hands',
        value: 'hands'
    }, {
        label: 'Biggest winner',
        value: 'bigger_winner'
    }, {
        label: 'Biggest looser',
        value: 'bigger_looser'
    }];

    const visualSubTypes =[{
        label: 'By balance',
        value: 'balance'
    }, {
        label: 'By bb / 100',
        value: 'bb'
    }];
    const [selectedType, setSelectedType] = useState<ISelect[]>([visualTypes[0]]);
    const [selectedSubType, setSelectedSubType] = useState<ISelect[]>([]);

    useEffect(() => {
        setType(selectedType?.[selectedType.length - 1]?.value);
        if (selectedType.length > 1) {
            setSelectedType([selectedType[selectedType.length - 1]]);
        }
    }, [selectedType]);

    useEffect(() => {
        setSubType(selectedSubType?.[selectedSubType.length - 1]?.value);
        if (selectedSubType.length > 1) {
            setSelectedSubType([selectedSubType[selectedSubType.length - 1]]);
        }
    }, [selectedSubType]);
    
    return (<Wrapper $autoMargin={false}>
        <div>
            <label>Order</label>
            <MultiSelect
                hasSelectAll={false}
                disableSearch={true}
                options={visualTypes}
                value={selectedType}
                onChange={setSelectedType}
                labelledBy="Stakes"
            />
        </div>
        {
            selectedType?.[0]?.value?.includes('bigger') ? (<div>
                <label>Type</label>
                <MultiSelect
                    hasSelectAll={false}
                    disableSearch={true}
                    options={visualSubTypes}
                    value={selectedSubType}
                    onChange={setSelectedSubType}
                    labelledBy="Stakes"
                />
            </div>) : <div style={{width: 250}}></div>
        }
        <div className='search'>
            <button onClick={() => setSearchTrigger((prevState: boolean) => !prevState)}>SEARCH</button>
        </div>
    </Wrapper>)
}