import React, { 
    FC, 
    useState, 
} from "react";

import './Table.css';
import { ITable } from "../pages/Tables";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import icons, { Icon } from "../commons/Icon";
import ITag from "../../types/Tag.types";
import { Tag } from "../commons/Tag";
import Autosuggest from 'react-autosuggest';
import IPlayerGlobal from "../../types/IPlayerGlobal";

export const Table: FC<ITable> = ({
    nicks,
    idx,
    id,
    removeItem,
    renderRemoveIcon,
    players,
    setTableNicks,
}) => {

    const [suggestions, setSuggestions] = useState<string[][]>([[],[],[],[],[],[],[],[],[]]);
  
    const onSuggestionsFetchRequested = ({value}: { value: string}, idx: number) => {
        if (value) {
            const inputValue = value?.trim()?.toLowerCase();
            const filteredSuggestions = players?.filter((player : IPlayerGlobal) => player.playerNick.toLowerCase().startsWith(inputValue))
                .map(item => item.playerNick);
            setSuggestions((prevState: string[][]) => {
                prevState[idx] = filteredSuggestions || [];
                return prevState;
            });
        }
      };
    
    const onSuggestionsClearRequested = (idx: number) => {
        setSuggestions((prevState: string[][]) => {
            prevState[idx] = [];
            return prevState;
        });
    };

    const getSuggestionValue = (suggestion: string) => suggestion;

    const renderSuggestion = (suggestion: string) => (<div className='custom-suggestion'>{suggestion}</div>);

    return (<div>        
        <TableMain>
            <TableImage renderRemoveIcon={renderRemoveIcon}>
                <Row>
                    <Icon 
                        icon={icons.LeftArrowIcon} 
                        onClick={() => {
                            const newNicks = nicks;
                            newNicks.unshift(newNicks.pop() || '');
                            !!setTableNicks && setTableNicks(newNicks);
                        }} 
                        fill={colors.whiteStrong} 
                        stroke={colors.whiteStrong} />
                    <Icon 
                        icon={icons.RightArrowIcon} 
                        onClick={() => {
                            const newNicks = nicks;
                            newNicks.push(newNicks.shift() || '');
                            !!setTableNicks && setTableNicks(newNicks);
                        }} 
                        fill={colors.whiteStrong} 
                        stroke={colors.whiteStrong} />
                </Row>
                <div>{(idx || 0) + 1}</div>
                { renderRemoveIcon && <Icon 
                    icon={icons.CloseIcon} 
                    onClick={() => !!removeItem && removeItem(id)} 
                    fill={colors.whiteStrong} 
                    stroke={colors.whiteStrong}/> }
            </TableImage>
            { Array.from(Array(9).keys()).map(el => (<div>
                <HeaderRow>
                    <Autosuggest
                        suggestions={suggestions[el]}
                        onSuggestionsFetchRequested={(item) => onSuggestionsFetchRequested(item, el)}
                        onSuggestionsClearRequested={() => onSuggestionsClearRequested(el)}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={{
                            placeholder: 'Player nick',
                            value: (el < nicks?.length ? nicks[el] : ''),
                            onChange: (event: any, { newValue }: { newValue: string }) => {
                                const newNicks = [...nicks];
                                newNicks[el] = newValue;
                                setTableNicks && setTableNicks(newNicks);
                            },
                            style: { zIndex: '10000000', width: '100%', fontSize: '14px', textAlign: 'left', boxSizing: 'border-box', padding: '2px 8px', borderRadius: '4px 0 0 0' },
                        }}
                        theme={{
                            suggestionsContainer: 'custom-suggestions-container',
                            suggestion: 'custom-suggestion',
                            suggestionHighlighted: 'custom-suggestion-highlighted',
                        }} />
                        <Icon icon={icons.CopyIcon} onClick={() => {
                            if (el < nicks?.length) {
                                window.location.href = `/players/${nicks[el]}/hands`;
                            }
                        }} />
                </HeaderRow>
                <TagsRow>
                { players?.filter(player => player.playerNick === nicks[el])?.[0]?.tags?.filter(innerTag => !!innerTag).map((item: ITag) => ((<div
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.location.href = `/players/tag/${item.uuid}`;
                    }}>
                    <Tag
                        fontSize={10}
                        uuid={item?.uuid} 
                        name={item?.name} 
                        color={item?.color} 
                        bgColor={item?.bgColor}
                        isClickable={true} />
                </div>))) }
                </TagsRow>
            </div>)) }
        </TableMain>
    </div>);
};

const HeaderRow = styled.div`
    display: flex;
    gap: 8px;

    svg {
        width: 14px;
        margin-right: 4px;
    }

    path {
        fill: ${colors.white};
        stroke: ${colors.white};
    }
`;

const TableMain = styled.div`
    position: relative;
    max-height: 400px;
    overflow: visible;

    > div:not(:first-child) {
        position: relative;
        width: 140px;
        background-color: ${colors.background.secondary};
        padding: 2px;
        border-radius: 8px;
        border: 1px solid ${colors.white};
        height: 92px;
    }

    > div:nth-child(2) {
        top: -40px;
        margin-left: 50%;
        transform: translateX(-50%);
        border: 1px solid ${colors.success};
    }

    > div:nth-child(3) {
        top: -170px;
        left: 350px;
    }

    > div:nth-child(4) {
        top: -375px;
        left: 430px;
    }

    > div:nth-child(5) {
        top: -580px;
        left: 400px;
    }

    > div:nth-child(6) {
        top: -785px;
        left: 265px;
    }

    > div:nth-child(7) {
        top: -885px;
        left: 95px;
    }

    > div:nth-child(8) {
        top: -875px;
        left: -30px;
    }

    > div:nth-child(9) {
        top: -865px;
        left: -60px;
    }

    > div:nth-child(10) {
        top: -850px;
        left: 10px;
    }
`;

const TableImage = styled.div<{renderRemoveIcon?: boolean}>`
    width: 500px;
    font-size: 14px;
    height: 300px;
    border-radius: 200px;
    border: 5px solid ${colors.black};
    background: ${colors.pokerTable};
    color: ${colors.black};
    font-size: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: -20px;
    padding-bottom: 10px;

    ${p => p.renderRemoveIcon && `> div:last-child:not(:first-child) {
        position: relative;
        top: -300px;
        left: 320px;
    }`} 
    
    > div:first-child {
        margin-top: ${p => !p.renderRemoveIcon ? -30 : 18}px
    }
`;

const TagsRow = styled.div`
    display: flex;
    gap: 4px;
    flex-wrap: wrap;
    margin-top: -2px;

    > div {
        font-size: 12px;
    }

    overflow: scroll;
    height: calc(100% - 24px);
`;

const Row = styled.div`
    display: flex;
    gap: 16px;
`;