import React, { 
    FC, 
    useEffect, 
    useState, 
} from 'react';

import { MultiSelect } from "react-multi-select-component";
import { Wrapper } from './Filters.styles';
import { 
    IProps, 
    ISelect, 
} from './Filters.types';

export const Filters: FC<IProps> = ({
    selectedStakes,
    setSelectedStakes,
    setSearchTrigger,
    withClearButton,
    withoutSearchButton,
}) => {

    const [visualStakes, setVisualStakes] = useState<ISelect[]>([]);
    const [selectedVisualStakes, setSelectedVisualStakes] = useState<ISelect[]>([]);

    useEffect(() => {
        const newStakes = selectedStakes.map((stake: number[]) => {
        return { 
            label: `${(Math.round(stake[0] * 10 / 1000) / 10) }k`,
            value: stake
        }});
        setVisualStakes(newStakes);
        setSelectedVisualStakes(newStakes);
    }, []);

    useEffect(() => {
        if (selectedVisualStakes.length > 1 && selectedVisualStakes.length !== visualStakes.length) {
            if (selectedVisualStakes.length !== visualStakes.length - 1) {
                setSelectedVisualStakes([selectedVisualStakes[selectedVisualStakes.length - 1]]);
            } else {
                const finalItem = visualStakes.find(item => !selectedVisualStakes.includes(item));
                finalItem && setSelectedVisualStakes([finalItem]);
            }
        }
        setSelectedStakes(selectedVisualStakes.map(stake => stake.value))
    }, [selectedVisualStakes]);

    return (<Wrapper $autoMargin={withClearButton}>
        <div>
            <label>Stakes</label>
            <MultiSelect
                options={visualStakes}
                value={selectedVisualStakes}
                onChange={setSelectedVisualStakes}
                labelledBy="Stakes"
            />
        </div>
        { withClearButton && (<div className='clear'>
            <button onClick={() => {
                setSelectedVisualStakes([]);
            }}>CLEAR</button>
        </div>) }
        { withoutSearchButton || (<div className='search'>
            <button onClick={() => setSearchTrigger((prevState: boolean) => !prevState)}>SEARCH</button>
        </div>) }
        
    </Wrapper>);
};