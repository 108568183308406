import React, { 
    FC, 
    useEffect, 
    useState, 
} from 'react';
import { Header } from '../Header/Header';
import IHand from '../../types/Hand.types';
import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { SearchBox } from '../SearchBox/SearchBox';
import { HandListContainer } from './Players';
import { useParams } from "react-router-dom";
import { Tag } from '../commons/Tag';
import ITag from '../../types/Tag.types';
import { TagsContainer } from '../HandDetails/Showdown';
import { Filters } from '../PlayerHands/Filters/Filters';
import { TypeFilters } from '../PlayersList/TypeFilters';
import IPlayerGlobal from '../../types/IPlayerGlobal';

export const numberWithSpaces = (x: number) => {
    var parts = x.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    return parts.join(".");
}

export const PlayersList: FC = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [players, setPlayers] = useState<IPlayerGlobal[]>([]);
    const [tagPlayers, setTagPlayers] = useState<string[]>([]);
    const [hands, setHands] = useState<IHand[]>([]);
    const [tag, setTag] = useState<ITag | null>(null);

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;

    const params = useParams();

    const [selectedStakes, setSelectedStakes] = useState<number[][]>([
        [500, 500],
        [1000, 1000],
        [2000, 2000],
        [5000, 5000],
        [10000, 10000]
    ]);
    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [type, setType] = useState<string>('newest');
    const [subType, setSubType] = useState<string>('balance');
    
    if (!userId) {
        window.location.href = '/login';
    }

    const onSearch = async (nick: string) => {
        if (!isLoading && !!nick && nick.length > 0) {
            setIsLoading(true);
            const res = await fetch(`http://146.59.44.6:8080/hand?nick=${nick}${!!userId ? '&uuid=' + userId : ''}${!!groupId ? '&groupId=' + groupId : ''}`,{ 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            });
            const text = await res.text();
            const json = await JSON.parse(text.trim());
            json?.length > 0 && (window.location.href = `/players/${nick}/hands`);
            setIsLoading(false);
        }

        document.title = `Players - Korean`;
    }

    useEffect(() => {
        if (params?.tagId) {
            fetch(`http://146.59.44.6:8080/tagPlayer/tag/${params?.tagId}${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(res => res.text())
            .then(text => {
                const jsonObj = JSON.parse(text);
                setTag(jsonObj);
                setTagPlayers(jsonObj.players.map((player: any) => player?.playerNick));
            });
        } else {
            setIsLoading(true);
            fetch(`http://146.59.44.6:8080/player/filtered/data?type=${type}${!!subType ? `&subtype=${subType}` : ''}${selectedStakes.length > 0 ? '&stakes=' + selectedStakes.map(stake => stake[0]) : ''}`, { 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(res => res.json())
            .then(json => {
                setPlayers(json);
                setIsLoading(false);
            })
        }     
    }, [searchTrigger]);

    return(<>
        <Header itemSelected={1} />
        <HandListContainer marginTop={227}>
            <SearchBox onSearch={onSearch} setHeroNick={() => {}} isLoading={isLoading} />
            { params?.tagId && (<TagsContainer>
                <Tag uuid={params?.tagId || ''} name={tag?.name || ''} color={tag?.color || ''} bgColor={tag?.bgColor || ''} />
            </TagsContainer>) }
            <Row $alignItems='center' $justifyContent={'center'} $gap={32}>
                <Filters selectedStakes={selectedStakes} setSelectedStakes={setSelectedStakes} setSearchTrigger={setSearchTrigger} withoutSearchButton={true} withClearButton={false} />
                <TypeFilters setType={setType} setSubType={setSubType} setSearchTrigger={setSearchTrigger} />
            </Row>
            <PlayersContainer manyCols={!!params?.tagId}>
                { !!params?.tagId || (<PlayerRow>
                    <div>NICK</div>
                    <div>HANDS</div>
                    <div>BALANCE</div>
                    <div>BALANCE IN BB / 100</div>
                    <div>LAST DATE</div>
                </PlayerRow>) }
            {
                !isLoading && !params?.tagId ? players?.map(mainPlayer => (<PlayerRow onClick={() => {
                    window.location.href = `/players/${mainPlayer.playerNick}/hands`;
                }}>
                    <div>{ mainPlayer.playerNick }</div>
                    <DataWithStakesDiv>
                        <div><span>Total:</span><span>{ numberWithSpaces(mainPlayer.handsPlayed || 0) }</span></div>
                        { (mainPlayer.handsPlayed500 || 0) > 0 && mainPlayer.stakes?.includes(500) && (<div><span>0,5 k:</span><span>{numberWithSpaces((mainPlayer.handsPlayed500 || 0)) }</span></div>) }
                        { (mainPlayer.handsPlayed1000 || 0) > 0 && mainPlayer.stakes?.includes(1000) && (<div><span>1 k:</span><span>{numberWithSpaces((mainPlayer.handsPlayed1000 || 0)) }</span></div>) }
                        { (mainPlayer.handsPlayed2000 || 0) > 0 && mainPlayer.stakes?.includes(2000) && (<div><span>2 k:</span><span>{numberWithSpaces((mainPlayer.handsPlayed2000 || 0)) }</span></div>) }
                        { (mainPlayer.handsPlayed5000 || 0) > 0 && mainPlayer.stakes?.includes(5000) && (<div><span>5 k:</span><span>{numberWithSpaces((mainPlayer.handsPlayed5000 || 0)) }</span></div>) }
                        { (mainPlayer.handsPlayed10000 || 0) > 0 && mainPlayer.stakes?.includes(10000) && (<div><span>10 k:</span><span>{numberWithSpaces((mainPlayer.handsPlayed10000 || 0)) }</span></div>) }
                    </DataWithStakesDiv>
                    <DataWithStakesDiv>
                        <div><span>Total:</span><span>{ mainPlayer.handsPlayed && numberWithSpaces(Math.round((mainPlayer.balance || 0) / 1000)) } k</span></div>
                        { (mainPlayer.handsPlayed500 || 0) > 0 && mainPlayer.stakes?.includes(500) && (<div><span>0,5 k:</span><span>{numberWithSpaces(Math.round((mainPlayer.balance500 || 0) / 1000)) } k</span></div>) }
                        { (mainPlayer.handsPlayed1000 || 0) > 0 && mainPlayer.stakes?.includes(1000) && (<div><span>1 k:</span><span>{numberWithSpaces(Math.round((mainPlayer.balance1000 || 0) / 1000)) } k</span></div>) }
                        { (mainPlayer.handsPlayed2000 || 0) > 0 && mainPlayer.stakes?.includes(2000) && (<div><span>2 k:</span><span>{numberWithSpaces(Math.round((mainPlayer.balance2000 || 0) / 1000)) } k</span></div>) }
                        { (mainPlayer.handsPlayed5000 || 0) > 0 && mainPlayer.stakes?.includes(5000) && (<div><span>5 k:</span><span>{numberWithSpaces(Math.round((mainPlayer.balance5000 || 0) / 1000)) } k</span></div>) }
                        { (mainPlayer.handsPlayed10000 || 0) > 0 && mainPlayer.stakes?.includes(10000) && (<div><span>10 k:</span><span>{numberWithSpaces(Math.round((mainPlayer.balance10000 || 0) / 1000)) } k</span></div>) }
                    </DataWithStakesDiv>
                    <DataWithStakesDiv>
                        <div><span>Total:</span><span>{ mainPlayer.handsPlayed && numberWithSpaces(Math.round(10000 * (mainPlayer.balanceInBB || 0) / mainPlayer.handsPlayed) / 100) }</span></div>
                        { (mainPlayer.handsPlayed500 || 0) > 0 && mainPlayer.stakes?.includes(500) && (<div><span>0,5 k:</span><span>{numberWithSpaces(Math.round(10000 * (mainPlayer.balanceInBB500 || 0) / (mainPlayer.handsPlayed500 || 1)) / 100) }</span></div>) }
                        { (mainPlayer.handsPlayed1000 || 0) > 0 && mainPlayer.stakes?.includes(1000) && (<div><span>1 k:</span><span>{numberWithSpaces(Math.round(10000 * (mainPlayer.balanceInBB1000 || 0) /(mainPlayer.handsPlayed1000 || 1)) / 100) }</span></div>) }
                        { (mainPlayer.handsPlayed2000 || 0) > 0 && mainPlayer.stakes?.includes(2000) && (<div><span>2 k:</span><span>{numberWithSpaces(Math.round(10000 * (mainPlayer.balanceInBB2000 || 0) / (mainPlayer.handsPlayed2000 || 1)) / 100) }</span></div>) }
                        { (mainPlayer.handsPlayed5000 || 0) > 0 && mainPlayer.stakes?.includes(5000) && (<div><span>5 k:</span><span>{numberWithSpaces(Math.round(10000 * (mainPlayer.balanceInBB5000 || 0) / (mainPlayer.handsPlayed5000 || 1)) / 100) }</span></div>) }
                        { (mainPlayer.handsPlayed10000 || 0) > 0 && mainPlayer.stakes?.includes(10000) && (<div><span>10 k:</span><span>{numberWithSpaces(Math.round(10000 * (mainPlayer.balanceInBB10000 || 0) / (mainPlayer.handsPlayed10000 || 1)) / 100) }</span></div>) }
                    </DataWithStakesDiv>
                    <div>{ mainPlayer.lastHand }</div>
                </PlayerRow>)) : tagPlayers?.map(mainPlayer => (<PlayerRow onClick={() => {
                    window.location.href = `/players/${mainPlayer}/hands`;
                }}>
                    <div>{ mainPlayer }</div>
                </PlayerRow>))
            }
            </PlayersContainer>
        </HandListContainer>
    </>);
};

const PlayersContainer = styled.div<{manyCols?: boolean}>`
    display: ${p => p.manyCols ? 'grid' : 'flex'};
    grid-template-columns: ${p => p.manyCols && '1fr 1fr 1fr 1fr'};
    > div {
        justify-content: ${p => p.manyCols && 'center'};
    }
    flex-direction: column;
    gap: ${p => p.manyCols ? 20 : 4}px;
    margin: 0 auto;
    padding: 32px;
    box-sizing: border-box;
    width: fit-content;
    color: ${colors.whiteStrong};
    font-size: 16px;
    flex-grow: 1;
    flex-basis: 0;

    > div${p => p.manyCols ? '' : ':not(:first-child)'} {
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }

    > div:first-child {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        border-top: ${p => !p.manyCols && `3px solid ${colors.white}`};
        border-bottom: ${p => !p.manyCols && `3px solid ${colors.white}`};
    }
`;

const PlayerRow = styled.div`
    flex-grow: 1;
    flex-basis: 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid ${colors.white};

    > div:first-child {
        border-left: 1px solid ${colors.white};
        font-size: 20px;
        font-weight: 600;
        justify-content: space-between;
        display: table-cell;

        > div {
            justify-content: space-between;
        }
    }

    > div {
        padding: 4px 8px;
        border-right: 1px solid ${colors.white};
        flex: 1;
        white-space: nowrap;
        text-align: center;
        display: table-cell;
        min-width: 190px;
    }
`;

const Row = styled.div<{ $alignItems?: string, $justifyContent?: string, $gap?: number }>`
    display: flex;
    flex-grow: 1;
    flex-basis: 0;
    gap: ${p => p.$gap || 16}px;
    justify-content: ${p => p.$justifyContent};
    align-items: ${p => p.$alignItems};
`;

const DataWithStakesDiv = styled.div`

    > div {
        border-top: 1px solid ${colors.white};
        padding: 6px 0;
        display: flex;
        justify-content: space-between;

        > span {
            display: flex;
        }

        > span:first-child {
            font-weight: 700;
        }
    }

    > div:first-child {
        border-top: none;
    }
`