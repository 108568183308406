import React, { 
    FC,
    useEffect,
    useRef,
    useState,
} from "react";
import { Header } from "../Header/Header";
import { 
    Container, 
    HandListContainer,
} from "./Players";
import IHand from "../../types/Hand.types";
import { HandDetails } from "../HandDetails/HandDetails";
import { PlayerHands } from "../PlayerHands/PlayerHands";
import styled from "styled-components";
import { colors } from "../../styles/colors";
import icons, { Icon } from "../commons/Icon";
import IPlayerGlobal from "../../types/IPlayerGlobal";
import { useParams } from "react-router-dom";
import { Tag } from "../commons/Tag";
import { TagsContainer } from "../HandDetails/Showdown";
import ITag from "../../types/Tag.types";
import { Filters } from "../PlayerHands/Filters/Filters";
import { Pagination } from "../PlayerHands/Pagination/Pagination";

export const Hands: FC = () => {

    const params = useParams();

    const [searchTrigger, setSearchTrigger] = useState<boolean>(false);
    const [selectedStakes, setSelectedStakes] = useState<number[][]>([]);
    const [limit, setLimit] = useState<number>(100);
    const [page, setPage] = useState<number>(1);
    const [isDetailsModal, setIsDetailsModal] = useState<boolean>(false);
    const [hand, setHand] = useState<IHand | null | undefined>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [playerHands, setPlayerHands] = useState<IHand[]>([]);
    const [allPlayers, setAllPlayers] = useState<IPlayerGlobal[]>([]);
    const [autoRefresh, setAutoRefresh] = useState<boolean>(!params?.tagId);
    const intervalRef = useRef<null | NodeJS.Timeout>(null);
    const [polishTime, setPolishTime] = useState<boolean>(true);
    const [tag, setTag] = useState<ITag | null>(null);
    const [allHandsSize, setAllHandsSize] = useState<number>(0);
    const [allHandsSizeWithStakes, setAlHandsSizeWithStakes] = useState<number>(0);

    const openDetails = (uuid: string) => {
        setHand(uuid !== undefined ? playerHands.find(hand => hand.uuid === uuid): null);
        setIsDetailsModal(true);
    };

    const user = JSON.parse(sessionStorage?.getItem('user') || '{}');
    const userId = user?.uuid;
    const groupId = user?.groupId;
    const isAdmin = user?.admin;
    
    if (!userId) {
        window.location.href = '/login';
    }

    const fetchData = () => {
        setIsLoading(true);

        fetch(`http://146.59.44.6:8080/hand/newest?withReviewed=false&limit=${limit}&page=${page}${!!userId ? '&uuid=' + userId : ''}${!!params?.tagId ? '&tagId=' + params?.tagId : ''}${!!groupId ? '&groupId=' + groupId : ''}${selectedStakes.length > 0 ? '&stakes=' + selectedStakes.map(stake => stake[0]) : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.text())
        .then(text => JSON.parse(text))
        .then(json => {
            setPlayerHands(json);
            setIsLoading(false);
        });

        if (params?.tagId) {
            fetch(`http://146.59.44.6:8080/tag/tag/${params?.tagId}${!!groupId ? '?groupId=' + groupId : ''}`,{ 
                method: "GET",
                headers: { 'Content-Type': 'application/json'}
            }).then(res => res.text())
            .then(text => {
                const jsonObj = JSON.parse(text);
                setTag(jsonObj);
            });
        }
    };

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/player${!!groupId ? '?groupId=' + groupId : ''}`,{ 
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.json() : [])
        .then(json => {
            setAllPlayers(json);
        });

        fetchData();
        intervalRef.current = setInterval(() => {
            fetchData();
        }, 30000);

        fetch(`http://146.59.44.6:8080/hand/countAll${!!groupId ? '?groupId=' + groupId : ''}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.text() : '0')
        .then(json => setAllHandsSize(parseInt(json)));

       return (() => !!intervalRef && clearInterval(intervalRef.current as NodeJS.Timeout));
    }, []);

    useEffect(() => {

        !!intervalRef && clearInterval(intervalRef.current as NodeJS.Timeout);
        if (autoRefresh) {
            intervalRef.current = setInterval(() => {
                fetchData();
            }, 30000);
        }
    }, [autoRefresh]);

    useEffect(() => {
        fetch(`http://146.59.44.6:8080/hand/countAll${!!groupId ? '?groupId=' + groupId : ''}${selectedStakes.length > 0 ? '&stakes=' + selectedStakes.map(stake => stake[0]) : ''}`, {
            method: "GET",
            headers: { 'Content-Type': 'application/json'}
        }).then(res => res.ok ? res.text() : '0')
        .then(json => setAlHandsSizeWithStakes(parseInt(json)));
        fetchData();
        !!intervalRef && clearInterval(intervalRef.current as NodeJS.Timeout);
        if (autoRefresh) {
            intervalRef.current = setInterval(() => {
                fetchData();
            }, 30000);
        }
    }, [searchTrigger]);

    return (<>
        <Header itemSelected={4} />
        <HandListContainer>
            { !params?.tagId ? (<AutoRefreshBox>
                    { autoRefresh ? (<Icon icon={icons.TickIcon} onClick={() => {}} width={20} stroke={colors.whiteStrong} fill={colors.whiteStrong}/>) : (<div></div>) }
                    <div onClick={(e) => {
                        setAutoRefresh((prevState: boolean) => !prevState);
                        e.preventDefault();
                    }}>AUTO REFRESH</div>
                </AutoRefreshBox>) : (<TagsContainer>
                    <Tag uuid={params?.tagId || ''} name={tag?.name || ''} color={tag?.color || ''} bgColor={tag?.bgColor || ''} />
                </TagsContainer>)
            }
            <TimeSection>
                <div className={polishTime ? 'selected' : ''}>{ new Date().toLocaleString("en-GB", {timeZone: "Europe/Warsaw"}) } PL</div>
                <div className={!polishTime ? 'selected' : ''}>{ new Date().toLocaleString("en-GB", {timeZone: "Asia/Seoul"}) } KOR</div>
                <div onClick={() => setPolishTime((prevState: boolean) => !prevState)}>SWITCH</div>
            </TimeSection>
            { isAdmin && allHandsSize > 0 && (<AllHandsBox>Hands: <b>{ allHandsSize }</b></AllHandsBox>) }
        {
            isDetailsModal && !!hand && (
            <Container onClick={() => setIsDetailsModal(false)}>
                <HandDetails 
                    setReviewed={(uuid: string) => {
                        setPlayerHands((prevState: IHand[]) => {
                            const newHand = prevState?.find(hand => hand?.uuid === uuid);
                            if (!!newHand) {
                                const index = prevState.indexOf(newHand);
                                newHand.reviewed = true;
                                prevState[index] = newHand;
                            }
                            return prevState;
                        })
                    }}
                    reviewed={hand.reviewed}
                    allPlayers={allPlayers}
                    title={hand.title}
                    uuid={hand.uuid}
                    players={hand.players}
                    bb={hand.bb}
                    sb={hand.sb}
                    pot={hand.pot}
                    blind={hand.blind.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    preflop={hand.preflop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    flop={hand.flop.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    turn={hand.turn.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    river={hand.river.sort((a, b) => (a?.actionId || 0) - (b?.actionId || 0))}
                    board={hand.board} 
                    heroCards={hand.heroCards}
                    date={hand.date}
                    hideDetails={() => setIsDetailsModal(false)}
                    fullRadius={true} 
                    heroNick={''}/>
            </Container>)
        }
        <Filters 
            withClearButton={true}
            selectedStakes={selectedStakes} 
            setSelectedStakes={setSelectedStakes}
            setSearchTrigger={setSearchTrigger} />
        <Pagination 
            isLoading={isLoading}
            pages={Math.ceil(allHandsSizeWithStakes / limit)}
            withSize={true} 
            setSearchTrigger={setSearchTrigger} 
            limit={limit}
            setLimit={setLimit}
            page={page}
            setPage={setPage}/>
        <PlayerHands
            isLoading={isLoading}
            available={true}
            showIsReviewed={true}
            polishTime={polishTime}
            hideTotalBalance={true}
            hands={playerHands}
            selectedHandUuid={''}
            openDetails={openDetails}
            heroNick={''}
            onSelectHand={() => {}} />
        <Pagination 
            isLoading={isLoading}
            pages={Math.ceil(allHandsSizeWithStakes / limit)}
            setSearchTrigger={setSearchTrigger} 
            page={page}
            setPage={setPage}/>
        </HandListContainer>
    </>)
};

export const AutoRefreshBox = styled.div<{width?: number}>`
    width: 100%;
    margin: 32px 0 0 0;
    display: flex;
    gap: 16px;
    color: ${colors.whiteStrong};
    justify-content: center;
    align-items: center;
    min-height: 32.5px;

    > div:last-child {
        width: ${p => p.width || 150}px;
        cursor: pointer;
    }

    > div:first-child {
        width: 20px !important;
        min-height: 10px;
    }
`;

const TimeSection = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    display: flex;
    gap: 32px;
    color: ${colors.whiteStrong};

    > div:not(:last-child) {
        font-size: 16px;
        width: 300px;
    
        &.selected {
            font-weight: 600;
            font-size: 22px;
    
        }
    }

    > div:last-child {
        padding: 8px 16px;
        border-radius: 16px;
        color: ${colors.white};
        border: 1px solid ${colors.white};
        cursor: pointer;

        &:hover {
            opacity: 0.8;
        }
    }
`;

const AllHandsBox = styled.div`
    max-width: 762px;
    text-align: right;
    font-size: 21px;
    line-height: 24px;
    margin: 16px auto;
    color: ${colors.whiteStrong};
`;